import EmblaCarousel from 'embla-carousel';
import * as bootstrap from 'bootstrap';
import AutoScroll from 'embla-carousel-auto-scroll';
import '../css/base.css';
import '../css/style.css';
import '../css/embla.css';
import data from '../data/data.json';

const OPTIONS = { align: 'start', dragFree: true, loop: true }

const emblaNode = document.querySelector('.embla');
const viewportNode = emblaNode.querySelector('.embla__viewport');

const emblaApi = EmblaCarousel(viewportNode, OPTIONS, [
  AutoScroll({
    playOnInit: true,
    speed: 1,
    stopOnInteraction: false,
  })
]);

window.onload = function () {
  const container = document.getElementById("embla__container");
  data.forEach(element => {
    let divSlide = document.createElement("div");
    let divScientist = document.createElement("div");
    let anchorScientist = document.createElement("a");

    divSlide.classList.add('embla__slide');
    divScientist.classList.add('embla__slide__scientist');
    divScientist.style.backgroundImage = `url(${element.photo})`;

    anchorScientist.href = `scientist-menu.html?video=${element.video}&markdown=${element.markdown}&photo=${element.photo}`;

    anchorScientist.append(divScientist);
    divSlide.append(anchorScientist);
    container.append(divSlide);
  });
}
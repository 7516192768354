[
    {
        "name": "Ana Maria Porras",
        "photo": "https://andregce.com/assets/tvicm/ana-maria-porras/Ana_Maria_Porras_Headshot-0001.jpg",
        "video": "https://andregce.com/assets/tvicm/ana-maria-porras/Quick_Q_AnaMaria_V08_Final.mp4",
        "markdown": "ana_maria_porras"
    },
    {
        "name": "Burcin Mutlu-Pakdil",
        "photo": "https://andregce.com/assets/tvicm/burcin-mutlu-pakdil/Burcin_Mutlu-Pakdil_Headshot_00002.jpg",
        "video": "https://andregce.com/assets/tvicm/burcin-mutlu-pakdil/IfThenSummit_Burcin_Mutlu_Pakdil_Video_1_TK_v2.mp4",
        "markdown": "burcin_mutlu_pakdil"
    },
    {
        "name": "Helen_Tran",
        "photo": "https://andregce.com/assets/tvicm/helen-tran/Helen_Tran_Headshot_00001.jpg",
        "video": "https://andregce.com/assets/tvicm/helen-tran/MIUN_20200414_TRAN_CLIP_1_v1.mp4",
        "markdown": "helen_tran"
    },
    {
        "name": "Jaye_Gardiner",
        "photo": "https://andregce.com/assets/tvicm/jaye-gardiner/Jaye_Gardiner_Headshot_00001.jpg",
        "video": "https://andregce.com/assets/tvicm/jaye-gardiner/Jaye_Social_Cutdown_v3.mp4",
        "markdown": "jaye_gardiner"
    },
    {
        "name": "Katy Croff Bell",
        "photo": "https://andregce.com/assets/tvicm/katy-croff-bell/Katy_Croff_Bell_Headshot-00001.jpg",
        "video": "https://andregce.com/assets/tvicm/katy-croff-bell/IfThenSummit_KatyCroff_Video_1_TK_v2.mp4",
        "markdown": "katy_croff_bell"
    },
    {
        "name": "Kiki Jenkins",
        "photo": "https://andregce.com/assets/tvicm/kiki-jenkins/Kiki_Jenkins_Headshot-00001.jpg",
        "video": "https://andregce.com/assets/tvicm/kiki-jenkins/Lekelia_Kiki_Jenkins_EPK_Seattle_Video_1_TK_v1.mp4",
        "markdown": "kiki_jenkins"
    },
    {
        "name": "Kristen Lear",
        "photo": "https://andregce.com/assets/tvicm/kristen-lear/Kristen_Lear_Headshot_00001.jpg",
        "video": "https://andregce.com/assets/tvicm/kristen-lear/05_LEAR_PROFILE.mp4",
        "markdown": "kristen_lear"
    },
    {
        "name": "Tammy Goulet",
        "photo": "https://andregce.com/assets/tvicm/tammy-goulet/Tammy_Goulet_Headshot_00001.jpg",
        "video": "https://andregce.com/assets/tvicm/tammy-goulet/15_GOULET_PROFILE.mp4",
        "markdown": "tammy_goulet"
    },
    {
        "name": "Jennifer Adler",
        "photo": "https://andregce.com/assets/tvicm/jennifer-adler/adler_headshot-7423.jpg",
        "video": "https://andregce.com/assets/tvicm/jennifer-adler/27_ADLER_PROFILE_CUTDOWN.mp4",
        "markdown": "jennifer_adler"
    },
    {
        "name": "Jessica Esquivel",
        "photo": "https://andregce.com/assets/tvicm/jessica-esquivel/Esquivel_R34NBQnA.jpg",
        "video": "https://andregce.com/assets/tvicm/jessica-esquivel/STEM_Journey_Esquivel.mp4",
        "markdown": "jessica_esquivel"
    }
]